import {
  CreateCareTeamArgs,
  CreateCareTeamError,
  CreateCareTeamReturns,
  UpdateCareTeamArgs,
  UpdateCareTeamError,
  UpdateCareTeamPayload,
  UpdateCareTeamReturns,
} from 'src/features/care-team/domain'
import {
  CreateCareTeamServiceErrorResponse,
  CreateCareTeamServicePayload,
  CreateCareTeamServiceResponse,
  UpdateCareTeamServiceArgs,
  UpdateCareTeamServiceErrorResponse,
  UpdateCareTeamServicePayload,
  UpdateCareTeamServiceResponse,
} from 'src/features/care-team/infrastructure'
import { PatientCareTeamFormFields } from 'src/features/care-team/presentation'
import { PatientIntakeFlowConditionV2 } from 'src/features/msk/presentation'
import { PatientV2 } from 'src/features/patients/domain'

// -----------
// CREATE CARE TEAM /
// -----------

type MapToCreateCareTeamServicePayload = (
  args: CreateCareTeamArgs
) => CreateCareTeamServicePayload

export const mapToCreateCareTeamServicePayload: MapToCreateCareTeamServicePayload =
  (args) => {
    const {
      locationProvider,
      providerGroupId,
      providerId,
      garnerRecommendationFlag,
      garnerUsed,
      garnerUsageExplanation,
      garnerUsageExplanationOther,
      requiredTherapy,
      referringProviderId,
      limitations,
      refRequestStatus,
      referralUploadLink,
      limitationsOther,
      requiredOtherTherapy,
      ...restPayload
    } = args

    const newPayload = restPayload as CreateCareTeamServicePayload

    newPayload.limitations = limitations ?? []

    if (providerGroupId) {
      newPayload.providerGroupId = providerGroupId
    }

    if (providerId) {
      newPayload.providerId = providerId
    }

    if (locationProvider) {
      newPayload.locationProvider = locationProvider
    }

    if (garnerRecommendationFlag !== undefined) {
      newPayload.garnerRecommendationFlag = garnerRecommendationFlag
    }

    if (garnerUsed !== undefined) {
      newPayload.garnerUsed = garnerUsed
    }

    if (garnerUsageExplanation) {
      newPayload.garnerUsageExplanation = garnerUsageExplanation
    }

    if (garnerUsageExplanationOther) {
      newPayload.garnerUsageExplanationOther = garnerUsageExplanationOther
    }

    if (requiredTherapy) {
      newPayload.requiredTherapy = requiredTherapy
    }

    if (referringProviderId) {
      newPayload.referringProviderId = referringProviderId
    }

    if (refRequestStatus) {
      newPayload.refRequestStatus = refRequestStatus
    }

    if (referralUploadLink) {
      newPayload.referralUploadLink = referralUploadLink
    }

    if (limitationsOther) {
      newPayload.limitationsOther = limitationsOther
    }

    if (requiredOtherTherapy) {
      newPayload.requiredOtherTherapy = requiredOtherTherapy
    }

    return newPayload
  }

type MapToCreateCareTeamError = (
  error: CreateCareTeamServiceErrorResponse
) => CreateCareTeamError

export const mapToCreateCareTeamError: MapToCreateCareTeamError = (error) => ({
  ...error,
})

type MapToCreateCareTeamReturns = (
  response: CreateCareTeamServiceResponse
) => CreateCareTeamReturns

export const mapToCreateCareTeamReturns: MapToCreateCareTeamReturns = (
  response
) => ({
  id: response.id,
  active: response.active ?? false,
  conditionId: response.conditionId,
  garnerRecommendationFlag: response.garnerRecommendationFlag ?? false,
  garnerUsed: response.garnerUsed ?? null,
  garnerUsageExplanation: response.garnerUsageExplanation ?? null,
  garnerUsageExplanationOther: response.garnerUsageExplanationOther ?? null,
  referralCoordinationFlag: response.referralCoordinationFlag ?? false,
  linkedAssignedPathwayId: response.linkedAssignedPathwayId ?? null,
  requiredTherapy: response.requiredTherapy ?? null,
  referringProviderId: response.referringProviderId ?? null,
  limitations: response.limitations ?? [],
  locationProvider: response.locationProvider,
  patientId: response.patientId,
  providerGroupId: response.providerGroupId,
  referringProviderGroupId: response.referringProviderGroupId ?? null,
  providerId: response.providerId,
  scheduled: response.scheduled,
  refRequestStatus: response.refRequestStatus ?? null,
  referralUploadLink: response.referralUploadLink ?? null,
  limitationsOther: response.limitationsOther ?? null,
  requiredOtherTherapy: response.requiredOtherTherapy ?? null,
})

// -----------
// UPDATE CARE TEAM /
// -----------

type MapToUpdateCareTeamServicePayload = (
  payload: UpdateCareTeamPayload
) => UpdateCareTeamServicePayload

export const mapToUpdateCareTeamServicePayload: MapToUpdateCareTeamServicePayload =
  (payload) => {
    const {
      locationProvider,
      providerGroupId,
      providerId,
      requiredTherapy,
      referringProviderId,
      garnerUsageExplanationOther,
      limitations,
      refRequestStatus,
      referralUploadLink,
      limitationsOther,
      requiredOtherTherapy,
      ...restPayload
    } = payload

    const newPayload = restPayload as UpdateCareTeamServicePayload

    newPayload.limitations = limitations ?? []
    if (providerGroupId) {
      newPayload.providerGroupId = providerGroupId
    }

    if (providerId) {
      newPayload.providerId = providerId
    }

    if (locationProvider) {
      newPayload.locationProvider = locationProvider
    }

    if (
      garnerUsageExplanationOther !== undefined &&
      garnerUsageExplanationOther !== ''
    ) {
      newPayload.garnerUsageExplanationOther = garnerUsageExplanationOther
    } else {
      newPayload.garnerUsageExplanationOther = null
    }

    if (requiredTherapy) {
      newPayload.requiredTherapy = requiredTherapy
    }

    if (referringProviderId) {
      newPayload.referringProviderId = referringProviderId
    }

    if (refRequestStatus) {
      newPayload.refRequestStatus = refRequestStatus
    }

    if (referralUploadLink) {
      newPayload.referralUploadLink = referralUploadLink
    }

    if (limitationsOther) {
      newPayload.limitationsOther = limitationsOther
    }

    if (requiredOtherTherapy) {
      newPayload.requiredOtherTherapy = requiredOtherTherapy
    }
    return newPayload
  }

type MapToUpdateCareTeamServiceArgs = (
  args: UpdateCareTeamArgs
) => UpdateCareTeamServiceArgs

export const mapToUpdateCareTeamServiceArgs: MapToUpdateCareTeamServiceArgs = (
  args
) => ({
  id: args.id,
  payload: mapToUpdateCareTeamServicePayload(args.payload),
})

type MapToUpdateCareTeamError = (
  error: UpdateCareTeamServiceErrorResponse
) => UpdateCareTeamError

export const mapToUpdateCareTeamError: MapToUpdateCareTeamError = (error) => ({
  ...error,
})

type MapToUpdateCareTeamReturns = (
  response: UpdateCareTeamServiceResponse
) => UpdateCareTeamReturns

export const mapToUpdateCareTeamReturns: MapToUpdateCareTeamReturns = (
  response
) => ({
  active: response.active ?? false,
  conditionId: response.conditionId,
  garnerRecommendationFlag: response.garnerRecommendationFlag ?? false,
  garnerUsed: response.garnerUsed ?? null,
  garnerUsageExplanation: response.garnerUsageExplanation ?? null,
  garnerUsageExplanationOther: response.garnerUsageExplanationOther ?? null,
  id: response.id,
  locationProvider: response.locationProvider,
  patientId: response.patientId,
  providerGroupId: response.providerGroupId,
  referringProviderGroupId: response.referringProviderGroupId ?? null,
  providerId: response.providerId,
  scheduled: response.scheduled,
  referralCoordinationFlag: response.referralCoordinationFlag ?? false,
  linkedAssignedPathwayId: response.linkedAssignedPathwayId ?? null,
  requiredTherapy: response.requiredTherapy ?? null,
  referringProviderId: response.referringProviderId ?? null,
  limitations: response.limitations ?? [],
  refRequestStatus: response.refRequestStatus ?? null,
  referralUploadLink: response.referralUploadLink ?? null,
  limitationsOther: response.limitationsOther ?? null,
  requiredOtherTherapy: response.requiredOtherTherapy ?? null,
})

type MapToCreateCareTeamArgs = (
  patient: PatientV2,
  condition: PatientIntakeFlowConditionV2,
  formValues: PatientCareTeamFormFields
) => CreateCareTeamArgs

export const mapToCreateCareTeamArgs: MapToCreateCareTeamArgs = (
  patient,
  condition,
  formValues
) => ({
  patientId: patient.patientId,
  conditionId: condition.id,
  active: true,
  scheduled: formValues.scheduled ?? null,
  providerId: formValues.providerId ?? null,
  providerGroupId: formValues.providerGroupId ?? null,
  referringProviderGroupId: formValues.referringProviderGroupId ?? null,
  locationProvider: formValues.locationProvider ?? null,
  garnerUsed: formValues.garnerUsed === 'yes',
  garnerRecommendationFlag:
    (formValues.garnerUsed === 'yes' &&
      formValues.garnerUsageExplanation === 'Yes') ??
    null,
  garnerUsageExplanation: formValues.garnerUsageExplanation ?? null,
  garnerUsageExplanationOther: formValues.garnerUsageExplanationOther ?? null,
  referralCoordinationFlag: formValues.referralCoordinationFlag ?? false,
  linkedAssignedPathwayId: condition.associatedPathway?.id ?? null,
  requiredTherapy: formValues.requiredTherapy ?? null,
  referringProviderId: formValues.referringProviderId ?? null,
  limitations: formValues.limitations ?? [],
  refRequestStatus: formValues.refRequestStatus ?? null,
  referralUploadLink: formValues.referralUploadLink ?? null,
  limitationsOther: formValues.limitationsOther ?? null,
  requiredOtherTherapy: formValues.requiredOtherTherapy ?? null,
})
